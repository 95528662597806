import { decodeToken } from "react-jwt";
import { axiosLogin } from "../../api/axios";
import { LOGIN } from "../../constants";

export async function loginAPI(email, password) {
  const ris = await axiosLogin.post(LOGIN, {
    email,
    password,
  });
  return ris;
}

export const isTokenExpired = (token) => {
  const decodedToken = decodeToken(token)
  const d = new Date(decodedToken.exp * 1000);
  return d<Date.now()
}
