import { Alert, Container, Row, Col } from "react-bootstrap";
import Loading from "../components/Loading";
import StateTable from "../features/charts/StateTable";
import RegionTable from '../features/charts/RegionTable';
import ProvinceTable from '../features/charts/ProvinceTable';
import { useLocationData } from "../hooks/useLocationData";

function LocationData() {
  const { stateData, regionData, provinceData, errorLoc, loading } =
    useLocationData();
  return (
    <div style={{height: '100%'}}>
      {errorLoc ? (
        <Alert variant='danger'>{errorLoc}</Alert>
      ) : (
        <>
          {loading || !stateData || !regionData || !provinceData ? (
            <Loading />
          ) : (
            <Container className="mt-3">
              <Row>
                <Col md={6} className="p-3">
                  <div className="mb-3">
                    <h4>Stati</h4>
                  </div>
                  <StateTable stateData={stateData} />
                </Col>
                <Col md={6} className="p-3">
                  <div className="mb-3">
                    <h4>Regioni</h4>
                  </div>
                  <RegionTable regionData={regionData} />
                </Col>
              </Row>
              <Row>
                <Col className="p-3">
                  <div className="mb-2">
                    <h4>Province</h4>
                  </div>
                  <ProvinceTable provinceData={provinceData} />
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </div>
  );
}

export default LocationData;
