import { Container, Row, Col } from "react-bootstrap";
import HistoryChart from "./HistoryChart";
import DeadlineTable from "./DeadlineTable";

export default function Dashboard({
  title,
  companies,
  historyData,
  selectedCompany,
  setSelectedCompany,
  deadlineData,
}) {
  return (
    <>
      <Container className='mt-3'>
        <Row className="m-2">
          <Col>
            <HistoryChart
              companies={companies}
              historyData={historyData}
              currentCompany={selectedCompany}
              setCurrentCompany={setSelectedCompany}
            />
          </Col>
        </Row>
        <Row className="m-2">
          <Col>
            <DeadlineTable
              deadlineData={deadlineData}
              setSelectedCompany={setSelectedCompany}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
