import { Alert } from 'react-bootstrap';
import Loading from '../components/Loading';

import Dashboard from '../features/charts/Dashboard';
import { useDashboardData } from '../hooks/useDashboardData';

function Social() {
  const {
    historyDataSoc,
    deadlineDataSoc,
    companies,
    loading,
    errorSoc,
    selectedCompany,
    setSelectedCompany,
   } = useDashboardData("soc");

  return (
    <>
      {errorSoc !== "" ? (
        <Alert variant='danger'>{errorSoc}</Alert>
      ) : (
        <>
          {loading || !historyDataSoc || !companies || !deadlineDataSoc ? (
            <Loading />
          ) : (
            <Dashboard
              companies={companies}
              deadlineData={deadlineDataSoc}
              historyData={historyDataSoc}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              title='Statistiche certificazioni sociali'
            />
          )}
        </>
      )}
    </>
  );
}

export default Social