function NotFound() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <h1>Ooops!</h1>

      <p>This page does not exist!</p>
    </div>
  )
}

export default NotFound