import { useState, useEffect } from "react";
import DataTableComponent from "../../components/DataTableComponent";

export default function StateTable({ stateData }) {
  const [filterValue, setFilterValue] = useState("");
  const [displayData, setDisplayData] = useState(stateData);

  useEffect(() => {
    setDisplayData(
      stateData.filter(
        (item) =>
          item.stato &&
          item.stato.toLowerCase().includes(filterValue.toLowerCase())
      )
    );
  }, [stateData, filterValue]);

  const columns = [
    {
      name: "Stato",
      selector: (row) => row.stato,
      sortable: true,
    },
    {
      name: "Numero compilazioni",
      selector: (row) => row.count,
      sortable: true,
    },
  ];

  const handleChange = (e) => {
    setFilterValue(e.target.value);
  };

  return (
    <>
      <DataTableComponent
        columns={columns}
        displayData={displayData}
        filterValue={filterValue}
        handleChange={handleChange}
        placeholder='Stato'
        defaultSortFieldId={2}
        defaultSortAsc={false}
      />
    </>
  );
}
