import { useEffect, useState } from "react";
import DataTableComponent from "../../components/DataTableComponent";
import { Link } from "react-router-dom";

export default function DeadlineTable({ deadlineData, setSelectedCompany }) {
  const [filterValue, setFilterValue] = useState("");
  const [displayData, setDisplayData] = useState(deadlineData);
  useEffect(() => {
    setDisplayData(
      deadlineData.filter(
        (item) =>
          item.azienda &&
          item.azienda.toLowerCase().includes(filterValue.toLowerCase())
      )
    );
  }, [filterValue]);

  const columns = [
    {
      name: "Azienda",
      selector: (row) => row.azienda,
      id: 1,
      sortable: true,
    },
    {
      name: "Certificazione",
      selector: (row) => row.certificazione,
      id: 2,
      sortable: true,
    },
    {
      name: "Scadenza",
      selector: (row) => row.scadenza,
      id: 3,
      sortable: true,
    },
    {
      name: "azienda_id",
      selector: (row) => row.id,
      omit: true,
      id: 4,
    },
  ];

  const handleSelect = (r) => {
    if (r.selectedCount > 0) {
      setSelectedCompany({
        name: r.selectedRows[0].azienda,
        id: r.selectedRows[0].azienda_id,
      });
    }
  };

  const handleChange = (e) => {
    setFilterValue(e.target.value);
  };

  const conditionalStyle = [
    {
      when: (row) => row.scadenza > new Date().toISOString().slice(0, 10),
      style: {
        backgroundColor: "rgba(255, 200, 0, 0.2)",
      },
    },
    {
      when: (row) => row.scadenza < new Date().toISOString().slice(0, 10),
      style: {
        backgroundColor: "rgba(255, 0, 0, 0.2)",
      },
    },
  ];

  const expandableRowsComponent = ({ data }) => (
    <div className='p-2'>
      <p>
        <strong>Certificazione: </strong> {data.certificazione}
      </p>
      {data.pec ? (
        <p>
          <strong>PEC: </strong>
          <Link
            to='#'
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:" + data.pec;
            }}>
            {data.pec}
          </Link>
        </p>
      ) : (
        ""
      )}
      {data.mail ? (
        <p>
          <strong>Email: </strong>
          <Link
            to='#'
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:" + data.mail;
            }}>
            {data.mail}
          </Link>
        </p>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <>
      <div className='mb-4'>
        <h4>Aziende con certificazione in scadenza</h4>
      </div>
      <DataTableComponent
        columns={columns}
        displayData={displayData}
        filterValue={filterValue}
        handleChange={handleChange}
        handleSelect={handleSelect}
        placeholder='Nome azienda'
        selectable={true}
        conditionalRowStyles={conditionalStyle}
        defaultSortFieldId={3}
        defaultSortAsc={false}
        expandableRows
        expandableRowsComponent={expandableRowsComponent}
      />
    </>
  );
}
