import { createSlice } from "@reduxjs/toolkit";
import {
  getCompanies,
  getDeadlineDataEnv,
  getDeadlineDataSoc,
  getHistoryDataEnv,
  getHistoryDataSoc,
  getPerformanceDataEnv,
  getPerformanceDataSoc,
  getProvinceCount,
  getRegionCount,
  getStateCount,
} from "./chartService";

const initialState = {
  historyDataEnv: null,
  deadlineDataEnv: null,
  historyDataSoc: null,
  deadlineDataSoc: null,
  stateCount: null,
  regionCount: null,
  provinceCount: null,
  performanceDataSoc: null,
  performanceDataEnv: null,
  companies: null,
  errorEnv: "",
  errorSoc: "",
  errorLoc: "",
  errorComp: "",
  errorPerf: "",
  loading: false,
};

export const chartDataSlice = createSlice({
  name: "chartData",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setHistoryDataEnv: (state, { payload }) => {
      state.historyDataEnv = payload;
      state.errorEnv = "";
    },
    setDeadlineDataEnv: (state, { payload }) => {
      state.deadlineDataEnv = payload;
      state.errorEnv = "";
    },
    setHistoryDataSoc: (state, { payload }) => {
      state.historyDataSoc = payload;
      state.errorSoc = "";
    },
    setDeadlineDataSoc: (state, { payload }) => {
      state.deadlineDataSoc = payload;
      state.errorSoc = "";
    },
    setCompanies: (state, { payload }) => {
      state.companies = payload;
      state.errorComp = "";
    },
    setPerformanceDataSoc: (state, { payload }) => {
      state.performanceDataSoc = payload;
      state.errorPerf = "";
    },
    setPerformanceDataEnv: (state, { payload }) => {
      state.performanceDataEnv = payload;
      state.errorPerf = "";
    },
    setErrorEnv: (state, { payload }) => {
      state.errorEnv = payload;
    },
    setErrorSoc: (state, { payload }) => {
      state.errorSoc = payload;
    },
    setErrorLoc: (state, { payload }) => {
      state.errorLoc = payload;
    },
    setErrorComp: (state, { payload }) => {
      state.errorComp = payload;
    },
    setErrorPerf: (state, { payload }) => {
      state.errorPerf = payload;
    },
    setStateCount: (state, { payload }) => {
      state.stateCount = payload;
      state.errorLoc = "";
    },
    setRegionCount: (state, { payload }) => {
      state.errorLoc = "";
      state.regionCount = payload;
    },
    setProvinceCount: (state, { payload }) => {
      state.errorLoc = "";
      state.provinceCount = payload;
    },
  },
});

export const {
  setLoading,
  setHistoryDataEnv,
  setDeadlineDataEnv,
  setHistoryDataSoc,
  setDeadlineDataSoc,
  setPerformanceDataSoc,
  setPerformanceDataEnv,
  setCompanies,
  setErrorEnv,
  setErrorSoc,
  setErrorLoc,
  setErrorComp,
  setErrorPerf,
  setStateCount,
  setRegionCount,
  setProvinceCount,
} = chartDataSlice.actions;

export const loadEnvironmentData = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(setLoading(true));
  try {
    if (!state.chartData.companies) {
      const companies = await getCompanies();
      dispatch(setCompanies(companies.data.data));
    }
    const historyDataEnv = await getHistoryDataEnv();
    const deadlineDataEnv = await getDeadlineDataEnv();
    dispatch(setHistoryDataEnv(historyDataEnv.data.data));
    dispatch(setDeadlineDataEnv(deadlineDataEnv.data.data));
  } catch (error) {
    console.log(error);
    dispatch(setErrorEnv("Loading data error"));
  }
  dispatch(setLoading(false));
};

export const loadSocialData = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(setLoading(true));
  try {
    if (!state.chartData.companies) {
      const companies = await getCompanies();
      dispatch(setCompanies(companies.data.data));
    }
    const historyDataSoc = await getHistoryDataSoc();
    const deadlineDataSoc = await getDeadlineDataSoc();
    dispatch(setHistoryDataSoc(historyDataSoc.data.data));
    dispatch(setDeadlineDataSoc(deadlineDataSoc.data.data));
  } catch (error) {
    console.log(error);
    dispatch(setErrorSoc("Loading data error"));
  }
  dispatch(setLoading(false));
};

export const loadLocationData = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const stateData = await getStateCount();
    const regionData = await getRegionCount();
    const provinceData = await getProvinceCount();
    dispatch(setStateCount(stateData.data.data));
    dispatch(setRegionCount(regionData.data.data));
    dispatch(setProvinceCount(provinceData.data.data));
  } catch (error) {
    console.log(error);
    dispatch(setErrorLoc("Loading data error"));
  }
  dispatch(setLoading(false));
};

export const loadCompanies = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const companies = await getCompanies();
    dispatch(setCompanies(companies.data.data));
  } catch (error) {
    console.log(error);
    dispatch(setErrorComp("Error loading companies"));
  }
  dispatch(setLoading(false));
};

export const loadPerformanceData = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const performanceDataSoc = await getPerformanceDataSoc();
    const performanceDataEnv = await getPerformanceDataEnv();
    dispatch(setPerformanceDataSoc(performanceDataSoc.data.data));
    dispatch(setPerformanceDataEnv(performanceDataEnv.data.data));
  } catch (error) {
    console.log(error);
    dispatch(setErrorPerf("Error loading performance data"));
  }
  dispatch(setLoading(false));
};

export const selectHistoryDataEnv = (state) => state.chartData.historyDataEnv;
export const selectDeadlineDataEnv = (state) => state.chartData.deadlineDataEnv;
export const selectHistoryDataSoc = (state) => state.chartData.historyDataSoc;
export const selectDeadlineDataSoc = (state) => state.chartData.deadlineDataSoc;
export const selectLoading = (state) => state.chartData.loading;
export const selectStateData = (state) => state.chartData.stateCount;
export const selectRegionData = (state) => state.chartData.regionCount;
export const selectProvinceData = (state) => state.chartData.provinceCount;
export const selectPerformanceDataSoc = (state) =>
  state.chartData.performanceDataSoc;
export const selectPerformanceDataEnv = (state) =>
  state.chartData.performanceDataEnv;
export const selectCompanies = (state) => state.chartData.companies;
export const selectErrorEnv = (state) => state.chartData.errorEnv;
export const selectErrorSoc = (state) => state.chartData.errorSoc;
export const selectErrorLoc = (state) => state.chartData.errorLoc;
export const selectErrorComp = (state) => state.chartData.errorComp;
export const selectErrorPerf = (state) => state.chartData.errorPerf;

export default chartDataSlice.reducer;
