import DataTable from "react-data-table-component";
import Checkbox from "@mui/material/Checkbox";
import { BACKGROUNDCOLOR, PRIMARY } from "../style/colors";

const styles = {
  rows: {
    selectedHighlightStyle: {
      "&:nth-of-type(n)": {
        backgroundColor: BACKGROUNDCOLOR,
      },
    },
  },
};

const checkbox = (props) => (
  <Checkbox
    {...props}
    sx={{
      color: PRIMARY,
      "&.Mui-checked": {
        color: PRIMARY,
      },
    }}
  />
);

export default function DataTableBase({ selectable, ...props }) {
  return (
    <>
      {selectable ? (
        <DataTable
          pagination
          selectableRowsComponent={checkbox}
          selectableRows
          selectableRowsSingle
          selectableRowsHighlight
          customStyles={styles}
          {...props}
        />
      ) : (
        <DataTable pagination customStyles={styles} {...props} />
      )}
    </>
  );
}
