import { useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import CompaniesSelector from "../../components/CompaniesSelector";
import { generatePdf } from "./pdfService";

export default function CertificationForm({ companies }) {
  const [currentCompany, setCurrentCompany] = useState(companies[0]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await generatePdf(currentCompany);
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
    setLoading(false);
  };

  return (
    <Form className='form-inline' onSubmit={handleSubmit}>
      <Row>
        <Form.Group as={Col} className='m-3'>
          <Form.Label>Seleziona azienda:</Form.Label>
          <CompaniesSelector
            companies={companies}
            currentCompany={currentCompany}
            setCurrentCompany={setCurrentCompany}
          />
        </Form.Group>
      </Row>
      {error && <Alert variant='danger'>{error}</Alert>}
      <Row>
        <Col className='m-3'>
          <Button disabled={!currentCompany} type='submit'>
            {loading ? (
              <Spinner animation='border' size='sm' />
            ) : (
              "Genera certificato"
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
