import { configureStore } from '@reduxjs/toolkit';
import chartDataReducer from '../features/charts/chartDataSlice';


export const store = configureStore(
  {
    reducer: {
      chartData: chartDataReducer
    },
    devTools: true
  },
);
