import LoginForm from "../features/auth/LoginForm";
import styles from './LoginPage.module.css';
import Logo from '../components/Logo';

export default function LoginPage(){
  
  return (
    <div className={styles.formPage}>
      <div className="mt-4">
        <Logo imgSize="100pt" />
      </div>
      <div className={styles.container}>
        <LoginForm />
      </div>
    </div>
  );
}