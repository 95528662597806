import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  CERTIFICATION,
  HOMEPAGE,
  LOCATION,
  LOGIN,
  PERFORMANCE,
  SOCIAL,
} from "../routes";
import { OTHERBG, SELECTCOLOR, SOCIALBG } from "../style/colors";
import Logo from "./Logo";

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const logoutUser = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    navigate(LOGIN, { replace: true });
  };

  return (
    <div
      className={`app`}
      style={{
        display: "flex",
        height: "100vh",
        overflow: "scroll initial",
      }}>
      <CDBSidebar
        textColor='#333'
        style={{
          height: "100vmax",
        }}
        backgroundColor={
          location.pathname === HOMEPAGE
            ? SELECTCOLOR
            : location.pathname === SOCIAL
            ? SOCIALBG
            : OTHERBG
        }>
        <CDBSidebarHeader prefix={<i className='fa fa-bars' />}>
          <Logo imgSize='70pt' />
        </CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <NavLink
              to={HOMEPAGE}
              className={({ isActive }) => (isActive ? "clicked" : undefined)}>
              <CDBSidebarMenuItem icon='fa-solid fa-tree'>
                Statistiche ambientali
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to={SOCIAL}
              className={({ isActive }) => (isActive ? "clicked" : undefined)}>
              <CDBSidebarMenuItem icon='fa-solid fa-users'>
                Statistiche sociali
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to={LOCATION}
              className={({ isActive }) => (isActive ? "clicked" : undefined)}>
              <CDBSidebarMenuItem icon='fa-solid fa-globe'>
                Compilazioni per zone
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to={PERFORMANCE}
              className={({ isActive }) => (isActive ? "clicked" : undefined)}>
              <CDBSidebarMenuItem icon='fa-solid fa-chart-bar'>
                Performance regionali
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              to={CERTIFICATION}
              className={({ isActive }) => (isActive ? "clicked" : undefined)}>
              <CDBSidebarMenuItem icon='fa-solid fa-file' iconType='solid'>
                Certificati
              </CDBSidebarMenuItem>
            </NavLink>
            <CDBSidebarMenuItem
              icon='fa-solid fa-lock'
              iconType='solid'
              onClick={logoutUser}>
              Logout
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
}
