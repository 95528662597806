import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectLoading,
  selectPerformanceDataEnv,
  selectPerformanceDataSoc,
  selectErrorPerf,
  loadPerformanceData,
} from "../features/charts/chartDataSlice";

export const usePerformanceData = () => {
  const loading = useSelector(selectLoading);
  const performanceDataSoc = useSelector(selectPerformanceDataSoc);
  const performanceDataEnv = useSelector(selectPerformanceDataEnv);
  const errorPerf = useSelector(selectErrorPerf);
  const dispatch = useDispatch()

  useEffect(() => {
    if(!performanceDataEnv || !performanceDataSoc) {
      dispatch(loadPerformanceData())
    }
  }, [performanceDataEnv, performanceDataSoc])
  
  return {
    loading,
    performanceDataEnv,
    performanceDataSoc,
    errorPerf
  }
};
