import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import CompaniesSelector from "../../components/CompaniesSelector";
import { mapValueToRank } from "../../utils";

export default function HistoryChart({
  companies,
  historyData,
  currentCompany,
  setCurrentCompany,
}) {
  const [currentData, setCurrentData] = useState(
    historyData.filter((d) => currentCompany && d.id === currentCompany.id)
  );
  const [labels, setLabels] = useState(
    currentData.map((d) => new Date(d.data_calcolo))
  );
  const [ranks, setRanks] = useState(currentData.map((d) => d.rank));

  useEffect(() => {
    setCurrentCompany(companies[0]);
  }, []);

  useEffect(() => {
    setCurrentData(
      historyData.filter((d) => currentCompany && d.id === currentCompany.id)
    );
  }, [currentCompany]);

  useEffect(() => {
    setLabels(currentData.map((d) => d.data_calcolo));
    setRanks(currentData.map((d) => d.rank));
  }, [currentData]);

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 1,
          callback: mapValueToRank,
        },
      },
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: "DD",
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context){
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += mapValueToRank(context.parsed.y);
            }
            return label;
          },
          title: function(context){
            return DateTime.fromMillis(
              context[0].parsed.x
            ).toFormat("dd LLL yyyy");
          }
        }
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Rank",
        data: ranks,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <>
      {currentCompany ? (
        <>
          <div className='mb-4'>
            <h4>Andamento rank {currentCompany.name}</h4>
          </div>
          <Row>
            <Col md={6}>
              <CompaniesSelector
                companies={companies}
                currentCompany={currentCompany}
                setCurrentCompany={setCurrentCompany}
              />
            </Col>
            <Col md={6}></Col>
          </Row>
          <div className='m-3'>
            <Line data={data} options={options} height='100pt' />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
