export const ENDPOINT = "https://app.oryenta.it/editoriaimp";
export const LOGIN = "/token";
export const PROVINCES = "/count/provincia";
export const REGIONS = "/count/regione";
export const COUNTRIES = "/count/stato";
export const COMPANY = "/azienda/";
export const DEADLINE = "/azienda/certificazionescadenza";
export const HISTORY = "/azienda/rank";
export const CERTIFICATION = "/azienda/certificazione";
export const PERFORMANCE = "/azienda/performance";
