import { axiosData } from "../../api/axios";
import {
  COMPANY,
  COUNTRIES,
  DEADLINE,
  HISTORY,
  PERFORMANCE,
  PROVINCES,
  REGIONS
} from "../../constants";

export const getCompanies = () => {
  return axiosData.get(COMPANY);
};

export const getHistoryDataEnv = () => {
  return axiosData.get(HISTORY, {
    params: {
      type: "env",
    },
  });
};

export const getDeadlineDataEnv = () => {
  return axiosData.get(DEADLINE, {
    params: {
      type: "env",
    },
  });
};

export const getHistoryDataSoc = () => {
  return axiosData.get(HISTORY, {
    params: {
      type: "social",
    },
  });
};

export const getDeadlineDataSoc = () => {
  return axiosData.get(DEADLINE, {
    params: {
      type: "social",
    },
  });
};

export const getProvinceCount = () => {
  return axiosData.get(PROVINCES);
};

export const getStateCount = () => {
  return axiosData.get(COUNTRIES);
};

export const getRegionCount = () => {
  return axiosData.get(REGIONS);
};

export const getPerformanceDataSoc = () => {
  return axiosData.get(PERFORMANCE, {
    params: {
      type: "social",
    },
  });
};

export const getPerformanceDataEnv = () => {
  return axiosData.get(PERFORMANCE, {
    params: {
      type: "env",
    },
  });
};
