import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadCompanies, selectCompanies, selectErrorComp, selectLoading } from '../features/charts/chartDataSlice'

export const useCompanies = () => {
  const companies = useSelector(selectCompanies)
  const loading = useSelector(selectLoading)
  const companiesError = useSelector(selectErrorComp)
  const dispatch = useDispatch()

  useEffect(() => {
    if(!companies) {
      dispatch(loadCompanies());
    }
  }, [companies])
  
  return {companies, loading, companiesError}
}