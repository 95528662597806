import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../components/Sidebar";
import { LOGIN } from "../../routes";
import { isTokenExpired } from "./loginService";

export default function RequireAuth({children}) {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  useEffect(() => {
    if(!token){
      navigate(LOGIN, {replace: true})
    } else if (isTokenExpired(token)){
        localStorage.removeItem("token");
        navigate(LOGIN, { replace: true });
    }
  },[])

  return (
    <div className='dashboard d-flex'>
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100%",
          overflowY: "hidden",
          marginLeft: "10pt"
        }}>
        {children}
      </div>
    </div>
  );
}