import { Alert, Container } from "react-bootstrap"
import PerformanceDashboard from "../features/charts/PerformanceDashboard"
import { usePerformanceData } from "../hooks/usePerformanceData"
import Loading from '../components/Loading'

export default function Performance() {
  const {loading,
    performanceDataEnv,
    performanceDataSoc,
    errorPerf} = usePerformanceData()
  return (
    <Container className="mt-3">
    {errorPerf !== "" ? <Alert variant="danger">{errorPerf}</Alert> : (
      <>
        {loading || !performanceDataEnv || !performanceDataSoc ? <Loading /> : (
          <PerformanceDashboard 
            performanceDataEnv={performanceDataEnv}
            performanceDataSoc={performanceDataSoc}
          />
        )}
      </>
    )}
    </Container>
  )
}
