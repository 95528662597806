import { Alert } from "react-bootstrap";
import Loading from "../components/Loading";
import Dashboard from "../features/charts/Dashboard";
import { useDashboardData } from "../hooks/useDashboardData";

export default function Environment() {
  const {
    historyDataEnv,
    deadlineDataEnv,
    companies,
    loading,
    errorEnv,
    selectedCompany,
    setSelectedCompany,
   } = useDashboardData("env");

  return (
    <>
      {errorEnv !== "" ? (
        <Alert variant='danger'>{errorEnv}</Alert>
      ) : (
        <>
          {loading || !historyDataEnv || !companies || !deadlineDataEnv ? (
            <Loading />
          ) : (
            <Dashboard
              companies={companies}
              deadlineData={deadlineDataEnv}
              historyData={historyDataEnv}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              title='Statistiche certificazioni ambientali'
            />
          )}
        </>
      )}
    </>
  );
}
