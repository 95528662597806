import { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { HOMEPAGE } from "../../routes";
import { isTokenExpired, loginAPI } from "./loginService";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !isTokenExpired(token)) {
      navigate(HOMEPAGE);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setError("");
      const ris = await loginAPI(email, password);
      localStorage.setItem("token", ris.data[0].token);
      setLoading(false);
      navigate(HOMEPAGE);
    } catch (error) {
      setError("Login Error");
      setLoading(false);
    }
  };

  return (
    <>
      {error ? <Alert variant='danger'>{error}</Alert> : ""}
      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3' controlId='emailfield'>
            <Form.Label>Email: </Form.Label>
            <Form.Control
              type='email'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='passwordfield'>
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type='password'
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant='primary' type='submit'>
            Invia
          </Button>
        </Form>
      )}
    </>
  );
}

export default LoginForm;
