import { Alert, Col, Container, Row } from 'react-bootstrap';
import Loading from '../components/Loading';
import CertificationForm from '../features/certification/CertificationForm';
import { useCompanies } from '../hooks/useCompanies';

function Certification() {
  const {loading, companies, errorComp} = useCompanies();

  return (
    <div style={{
      height: '100vh'
    }}>
      {errorComp ? (
        <Alert variant='danger'>{errorComp}</Alert>
      ) : (
        <>
          {loading || !companies ? (
            <Loading />
          ) : (
            <Container className='mt-3'>
              <Row className='mb-2'>
                <Col>
                  <h2>Generazione certificazione rank</h2>
                </Col>
              </Row>
              <CertificationForm companies={companies} />
            </Container>
          )}
        </>
      )}
    </div>
  );
}

export default Certification