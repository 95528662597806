import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
import JSZip from "jszip";
import { PDFDocument, rgb } from "pdf-lib";
import certificatoAmbientale from "../../assets/CERTIFICATO_AMBIENTALE.pdf";
import certificazioneSociale from "../../assets/CERTIFICATO_SOCIALE.pdf";
import spectralFontBold from "../../fonts/Spectral-Bold.ttf";
import spectralItalicFont from "../../fonts/Spectral-Italic.ttf";
import spectralFont from "../../fonts/Spectral-Regular.ttf";
import { mapValueToRank, removeSpaceFromString, titleCase } from "../../utils";
import { axiosData } from "../../api/axios";
import { CERTIFICATION } from "../../constants";

const getCompanyRank = (company_id) => {
  return axiosData.get(CERTIFICATION, {
    params: {
      id: company_id,
    },
  });
};

// Scrittura PDF
async function writePdf(
  existingPdfBytes,
  color,
  data,
  currentCompany,
  typeRank
) {
  const spectralFontData = await fetch(spectralFont).then((res) =>
    res.arrayBuffer()
  );
  const spectralBoldFontData = await fetch(spectralFontBold).then((res) =>
    res.arrayBuffer()
  );
  const spectralItalicFontData = await fetch(spectralItalicFont).then((res) =>
    res.arrayBuffer()
  );
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  pdfDoc.registerFontkit(fontkit);
  console.log(data);
  const fontRegular = await pdfDoc.embedFont(spectralFontData);
  const fontBold = await pdfDoc.embedFont(spectralBoldFontData);
  const fontItalic = await pdfDoc.embedFont(spectralItalicFontData);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();
  let date = new Date();

  const idString = `Certificato N° OIUC${data.id_code}|${date.getFullYear()}`;
  firstPage.drawText(idString, {
    x: width - fontRegular.widthOfTextAtSize(idString, 12) - 40,
    y: height - 120,
    font: fontRegular,
    size: 12,
  });

  firstPage.drawText(titleCase(currentCompany.name), {
    x: width / 2 - fontItalic.widthOfTextAtSize(currentCompany.name, 15) / 2,
    y: height / 2 + 200,
    size: 15,
    font: fontItalic,
    color: color,
  });

  firstPage.drawText(titleCase(data.indirizzo + " - " + data.citta), {
    x:
      width / 2 -
      fontItalic.widthOfTextAtSize(data.indirizzo + " - " + data.citta, 15) / 2,
    y: height / 2 + 200 - 20,
    size: 15,
    font: fontItalic,
    color: color,
  });

  firstPage.drawText(`P.IVA: ${data.p_iva.toUpperCase()}`, {
    x:
      width / 2 -
      fontItalic.widthOfTextAtSize(`P.IVA: ${data.p_iva.toUpperCase()}`, 15) /
        2,
    y: height / 2 + 200 - 40,
    size: 15,
    font: fontItalic,
    color: color,
  });

  firstPage.drawText(
    typeRank === "env"
      ? mapValueToRank(data.rank_env)
      : mapValueToRank(data.rank_soc),
    {
      x: width / 2 - 8,
      y: height / 2 + 80,
      size: 15,
      font: fontBold,
      color: color,
    }
  );

  firstPage.drawText(
    date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
      .replace(/ /g, "-"),
    {
      x: 60,
      y: typeRank === "env" ? 190 : 185,
      font: fontRegular,
      size: 12,
    }
  );

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
}

// Generazione PDF
export const generatePdf = async (currentCompany) => {
  const rankData = await getCompanyRank(currentCompany.id).then(
    (res) => res.data.data
  );
  if (!rankData) {
    throw new Error("No data found");
  }
  const zip = new JSZip();
  console.log(rankData);
  for (let i = 0; i < rankData.length; i++) {
    // Certificazione ambientale
    if (rankData[i].rank_env) {
      const existingPdfBytes = await fetch(certificatoAmbientale).then((res) =>
        res.arrayBuffer()
      );
      const colorGreen = rgb(33 / 255, 210 / 255, 168 / 255);
      const envBytes = await writePdf(
        existingPdfBytes,
        colorGreen,
        rankData[i],
        currentCompany,
        "env"
      );
      zip.file(
        `certification_${removeSpaceFromString(currentCompany.name)}_env.pdf`,
        envBytes
      );
    }

    // Certificazione sociale
    if (rankData[i].rank_soc) {
      const existingPdfBytesSoc = await fetch(certificazioneSociale).then(
        (res) => res.arrayBuffer()
      );
      const colorBrown = rgb(120 / 255, 63 / 255, 4 / 255);
      const socBytes = await writePdf(
        existingPdfBytesSoc,
        colorBrown,
        rankData[i],
        currentCompany,
        "soc"
      );
      zip.file(
        `certification_${removeSpaceFromString(currentCompany.name)}_soc.pdf`,
        socBytes
      );
    }
  }

  zip.generateAsync({ type: "blob" }).then((content) => {
    download(
      content,
      `certifications_${removeSpaceFromString(currentCompany.name)}.zip`
    );
  });
};
