import React, { useEffect, useState } from "react";
import DataTableComponent from "../../components/DataTableComponent";

export default function PerformanceTable({ data }) {
  const [displayData, setDisplayData] = useState(data);
  const [region, setRegion] = useState("");
  useEffect(() => {
    setDisplayData(
      data.filter(
        (item) => item.regione && item.regione.toLowerCase().includes(region.toLowerCase())
      )
    );
  }, [region]);

  const handleChange = (e) => {
    setRegion(e.target.value);
  };

  const columns = [
    {
      name: "Regione",
      selector: (row) => row.regione,
      sortable: true,
    },
    {
      name: "C",
      selector: (row) => row["1"],
      sortable: true,
    },
    {
      name: "C+",
      selector: (row) => row["2"],
      sortable: true,
    },
    {
      name: "C++",
      selector: (row) => row["3"],
      sortable: true,
    },
    {
      name: "B",
      selector: (row) => row["4"],
      sortable: true,
    },
    {
      name: "B+",
      selector: (row) => row["5"],
      sortable: true,
    },
    {
      name: "B++",
      selector: (row) => row["6"],
      sortable: true,
    },
    {
      name: "A",
      selector: (row) => row["7"],
      sortable: true,
    },
    {
      name: "A+",
      selector: (row) => row["8"],
      sortable: true,
    },
    {
      name: "A++",
      selector: (row) => row["9"],
      sortable: true,
    },
  ];
  return (
    <DataTableComponent
      columns={columns}
      displayData={displayData}
      filterValue={region}
      handleChange={handleChange}
      placeholder='Regione'
      selectable={false}
    />
  );
}
