import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadLocationData,
  selectErrorLoc,
  selectLoading,
  selectProvinceData,
  selectRegionData,
  selectStateData
} from "../features/charts/chartDataSlice";

export const useLocationData = () => {
  const stateData = useSelector(selectStateData);
  const regionData = useSelector(selectRegionData);
  const provinceData = useSelector(selectProvinceData);
  const errorLoc = useSelector(selectErrorLoc);
  const loading = useSelector(selectLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!stateData || !regionData || !provinceData) {
      dispatch(loadLocationData());
    }
  }, [stateData, regionData, provinceData]);

  return {
    stateData,
    regionData,
    provinceData,
    errorLoc,
    loading,
  };
};
