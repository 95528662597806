import { ENDPOINT } from "../constants";
import axios from "axios";

export const axiosLogin = axios.create({
  baseURL: ENDPOINT
});

const token = localStorage.getItem('token')

export const axiosData = axios.create({
  baseURL: ENDPOINT,
  headers: {
    'Authorization': `Bearer ${token}`
  }
})