import { Routes, Route } from "react-router-dom";
import RequireAuth from "./features/auth/RequireAuth";
import Environment from "./pages/Environment";
import LocationData from "./pages/LocationData";
import LoginPage from "./pages/LoginPage";
import Social from "./pages/Social";
import Certification from './pages/Certification'
import { CERTIFICATION, HOMEPAGE, LOCATION, LOGIN, PERFORMANCE, SOCIAL } from "./routes";
import Performance from "./pages/Performance";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Routes>
      <Route
        path={HOMEPAGE}
        element={
          <RequireAuth>
            <Environment />
          </RequireAuth>
        }
      />
      <Route
        path={SOCIAL}
        element={
          <RequireAuth>
            <Social />
          </RequireAuth>
        }
      />
      <Route
        path={LOCATION}
        element={
          <RequireAuth>
            <LocationData />
          </RequireAuth>
        }
      />
      <Route
        path={CERTIFICATION}
        element={
          <RequireAuth>
            <Certification />
          </RequireAuth>
        }
      />
      <Route
        path={PERFORMANCE}
        element={
          <RequireAuth>
            <Performance />
          </RequireAuth>
        }
      />
      <Route path={LOGIN} element={<LoginPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
