import {Spinner} from 'react-bootstrap'

function Loading() {
  return (
    <div className='d-flex justify-content-center'>
      <Spinner animation='border' role='status' className='m-10-auto'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loading