import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadEnvironmentData,
  loadSocialData,
  selectCompanies,
  selectDeadlineDataEnv,
  selectDeadlineDataSoc,
  selectErrorEnv,
  selectErrorLoc,
  selectErrorSoc,
  selectHistoryDataEnv,
  selectHistoryDataSoc,
  selectLoading,
} from "../features/charts/chartDataSlice";

export const useDashboardData = (sourceData) => {
  const historyDataEnv = useSelector(selectHistoryDataEnv);
  const historyDataSoc = useSelector(selectHistoryDataSoc);
  const deadlineDataEnv = useSelector(selectDeadlineDataEnv);
  const deadlineDataSoc = useSelector(selectDeadlineDataSoc);
  const companies = useSelector(selectCompanies);
  const errorEnv = useSelector(selectErrorEnv);
  const errorSoc = useSelector(selectErrorSoc);
  const errorLoc = useSelector(selectErrorLoc);
  const loading = useSelector(selectLoading);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (sourceData === "soc" && (!historyDataSoc || !deadlineDataSoc)) {
      dispatch(loadSocialData());
    }
    if (sourceData === "env" && (!historyDataEnv || !deadlineDataEnv)) {
      dispatch(loadEnvironmentData());
    }
  }, [
    sourceData,
    historyDataEnv,
    historyDataSoc,
    deadlineDataEnv,
    deadlineDataSoc,
  ]);
  return {
    historyDataEnv,
    deadlineDataEnv,
    historyDataSoc,
    deadlineDataSoc,
    companies,
    loading,
    errorEnv,
    errorSoc,
    errorLoc,
    selectedCompany,
    setSelectedCompany,
  };
};
