import { useMemo } from "react";
import { Row, Col, Form } from "react-bootstrap";
import DataTableBase from "./DataTableBase";
import ExportButton from "./ExportButton";
import { downloadCSV } from "../utils";

export default function DataTableComponent({
  placeholder,
  filterValue,
  handleChange,
  displayData,
  columns,
  handleSelect,
  selectable,
  ...props
}) {
  const actionsMemo = useMemo(
    () => (
      <ExportButton
        onExport={() => downloadCSV(displayData)}
        label='Esporta in CSV'
      />
    ),
    [displayData]
  );

  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Control
            size='sm'
            placeholder={placeholder}
            value={filterValue}
            onChange={handleChange}
          />
        </Col>
        <Col md={6} className="pb-3">{actionsMemo}</Col>
      </Row>
      <Row>
        <DataTableBase
          columns={columns}
          data={displayData}
          onSelectedRowsChange={handleSelect}
          selectable={selectable}
          {...props}
        />
      </Row>
    </>
  );
}
