import {Row, Col} from 'react-bootstrap'
import PerformanceTable from './PerformanceTable'

export default function PerformanceDashboard({
  performanceDataSoc,
  performanceDataEnv
}) {
  return (
    <>
      <Row className='mb-3'>
        <Col>
          <h4>Performance sociali</h4>
          <PerformanceTable data={performanceDataSoc} className='p-2 mb-3' />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <h4>Performance ambientali</h4>
          <PerformanceTable data={performanceDataEnv} className='p-2 mb-3' />
        </Col>
      </Row>
    </>
  );
}
