import Select from 'react-select'
import {BACKGROUNDCOLOR, PRIMARY, SELECTCOLOR} from '../style/colors'

export default function CompaniesSelector({companies, currentCompany, setCurrentCompany}){
  const styles = {
    control: (provided, state) => {
      return { 
      ...provided, 
      borderColor: 
        state.isFocused ? PRIMARY 
        : undefined}
    },
    option: (style, state) => {
      return {
        ...style,
        backgroundColor: state.isSelected
          ? PRIMARY
          : state.isFocused
          ? BACKGROUNDCOLOR
          : undefined,

        ":active": {
          ...styles[":active"],
          backgroundColor: !state.isDisabled
            ? state.isSelected
              ? PRIMARY
              : SELECTCOLOR
            : undefined,
        },
      };
    }
  };
  return (
    <Select
      options={companies.map((c) => {
        return { value: c.id, label: c.name };
      })}
      onChange={(e) => setCurrentCompany({id: e.value, name: e.label})}
      value={currentCompany ? { value: currentCompany.id, label: currentCompany.name } : null}
      styles={styles}
    />
  );
}